/* global Component */
class priceComponent extends Component {

    static name() {
        return "priceComponent";
    }

    static componentName() {
        return "priceComponent";
    }

    data(){
        return {
            currentItemPriceData:null,
            itemCode:null
        };
    }

    created(){
        return async function (){
            this.itemCode = this.item.Code;
        };
    }

    getProps() {
        return  ['item'];
    }

    getComputed(){
        return {
            itemStore:function (){
                return this.$store.state.items_store.get(this.item.Code);
            },
            currentItemPrice:function () {
                const originalPrice = this.$store.getters.showItemPrice(this.itemStore);
                if(this.$store.getters.getSetting.ItemPriceHideDecimals)
                    return Math.trunc(originalPrice);
                return originalPrice;
            },
            Currency:function () {
                return this.$store.getters.getCurrency;
            },
            formatOption: function () {
                return this.$store.getters.getSetting.DecimalSeparator;
            },
        };
    }

    getWatch() {
        return  {
            "itemStore.RowNet": function (newValue,oldValue) {
                this.updateItemPrice();
            },
            "itemStore.RowTotal": function (newValue,oldValue) {
                this.updateItemPrice();
            }
        };
    }

    getMethods() {
        return {
            updateItemPrice:this.updateItemPrice,
            formatNumber:this.formatNumber,
            applySeparators:this.applySeparators
        };
    }

    updateItemPrice(){
        this.currentItemPriceData = this.$store.getters.showItemPrice(this.itemStore);
    }

    formatNumber(price) {
        let formatPrice = parseFloat(new Number(price).toFixed(2));
        let maximumFractionDigits = 2;
        if(this.itemPriceHideDecimals) {
            formatPrice =  Math.trunc(formatPrice);
            maximumFractionDigits = 0;
        }
        formatPrice = this.applySeparators(formatPrice, maximumFractionDigits); 
        return formatPrice;
    }

    applySeparators(number, maximumFractionDigits) {
        const lang = navigator.language || navigator.userLanguage;
        let [thousandSeparator, decimalSeparator] = ["", ""];
        let formatOption = this.formatOption;
        
        switch (formatOption) {
            case 1:
                thousandSeparator = ".";
                decimalSeparator = ",";
                break;
            case 2:
                thousandSeparator = ",";
                decimalSeparator = ".";
                break;
            case 3:
                return number.toLocaleString(lang, {minimumFractionDigits: maximumFractionDigits, maximumFractionDigits});
            default:
                throw new Error("Formato no válido");
        }
    
        let hasDecimals = !Number.isInteger(number);
        let formattedNumber = number.toString();
        let [integerPart, decimalPart] = formattedNumber.split(".");

        if(!hasDecimals) {
            integerPart = integerPart + decimalSeparator + "00";
        } else {
            if(decimalPart.length < 2) {
                decimalPart += "0";
            }
        }

        if (Math.abs(number) >= 1000) {
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
        }
    
        return hasDecimals ? integerPart + decimalSeparator + decimalPart : integerPart;
    }

    getTemplate() {
        return `<div class="price-container">
                <template v-if="currentItemPrice==null">
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style="width: 80%"></div>
                    </div>
                </template>
                <template v-else>
                    <p class="card-text mb-2 item-price"> 
                        <strong v-if="item.CrossedOutPrice" class="crossedoutprice">$ {{formatNumber(item.CrossedOutPrice)}}</strong>
                        <strong>$ {{formatNumber(currentItemPrice)}} </strong>
                        <small v-if="Currency">{{Currency}}/{{item.Unit}}</small><small v-else>{{item.Unit}}</small>
                    </p>
                </template>
            </div>`;
    }
}

priceComponent.registerComponent();